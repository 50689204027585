import React, { useState, useEffect,useMemo } from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {FormControl,useMediaQuery,Collapse,Divider, Box, Typography,IconButton,MenuItem,Select, CircularProgress} from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ButtonTimeFrame from '../components/ButtonTimeFrame';
import ButtonFormatValues from '../components/ButtonFormatValues';
import FilterSlider from '../components/FilterSlider';
import TableTrended from '../components/TableTrended';
import TableMetrics from '../components/TableMetrics';
import GraphTrended from '../components/GraphTrended';

const SecurityAnalysisKeyMetrics = ({open,handleToggleCollapse,ticker}) => {  
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        // Ensure ticker is a string and log it for debugging
        const dynamicDataAnnual = await import(`../data/stockpage/financial/annual/${ticker}.json`);
        const dynamicDataQtr = await import(`../data/stockpage/financial/quarterly/${ticker}.json`);
        const dynamicKeyStats = await import(`../data/stockpage/keystatistics/${ticker}.json`);
        const keyStatsFilterCriteria = ['Market Cap','Enterprise Value', 'EV / Revenue', 'EV / GM','Price / FCF','Close Date']; // Example metrics to include
        // Set the data in state, assuming dynamicData.default is an array of arrays as per provided data format
        if (Array.isArray(dynamicDataAnnual.default.data)) {
          setAnnualData(dynamicDataAnnual.default.data);
          setAnnualMapping(dynamicDataAnnual.default.mappingTable);
          // Check the last revenue value and set the scale to 'B' if it's greater than $10B
          const revenueData = dynamicDataAnnual.default.data.find(item => item.field === 'Revenue');
          if (revenueData) {
            const lastRevenue = revenueData[Object.keys(revenueData).pop()]; // Get the last value
            if (lastRevenue > 2000000000) { // Check if the last revenue is greater than $10B

              setAlignmentScale('B');
            }
          }
        }
        else {
          console.error('Unexpected data format:', dynamicDataAnnual);
        } 
        if (Array.isArray(dynamicDataQtr.default.data)) {
          setQtrData(dynamicDataQtr.default.data);
          setQtrMapping(dynamicDataQtr.default.mappingTable);
        }
        else {
          console.error('Unexpected data format:', dynamicDataQtr);
        }
        if (Array.isArray(dynamicKeyStats.default[0].keyStats)) {
          setKeyStats(dynamicKeyStats.default[0].keyStats.filter(item =>
            keyStatsFilterCriteria.includes(item.field)));
        }
        else {
          console.error('Unexpected data format:', dynamicKeyStats);
        }
      } catch (error) {
        console.error(`Error fetching data for ${ticker}:, error`);
      }
     finally {
      setLoading(false); // End loading
    }
    };

    fetchData();
  }, [ticker]);
  const theme = useTheme();
  const isTablet = useMediaQuery('(max-width:1080px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  const isPhablet =useMediaQuery('(max-width:700px)');
  const [annualData, setAnnualData] = useState([]);
  const [qtrData, setQtrData] = useState([]);
  const [keyStats, setKeyStats] = useState([]);
  const [annualMapping,setAnnualMapping] = useState([]);
  const [qtrMapping,setQtrMapping] = useState([]);
  const [alignment, setAlignment] = React.useState('annual');
  const [alignmentGraph,setAlignmentGraph] = useState('quarterly')
  const [selectedTrendedMetric, setSelectedTrendedMetric] = useState('Revenue');
  const [metrics,setMetrics] = useState(['ARR','Cloud ARR','Non-Cloud ARR','Calculated Billings','Current Bookings','Bookings','Current RPO','RPO','Non-Cloud Revenue','Cloud Revenue','Revenue','Cloud Mix','RPO Duration','Current RPO Mix','Customers','Customers >$100k','Customers >$1M','Payback Period','Rule of 40','Net Retention Rate'])
  const [nivoData, setNivoData] = useState([])
  const [mappingArray, setMappingArray] = useState([])
  const [filteredData, setFilteredData] = useState([])


  const handleChange = (event, newAlignment) => {
      if (newAlignment !== null){
        setAlignment(newAlignment);
        setAlignmentGraph(newAlignment)
      }
    };
    const handleChangeGraph = (event, newAlignment) => {
      if (newAlignment !== null){
        setAlignmentGraph(newAlignment);
      }
    };

    const handleChangeScale = (event, newAlignment) => {
    
      if (newAlignment !== null){
        setAlignmentScale(newAlignment);
      }
    };
    
    
    
  
  const [alignmentScale, setAlignmentScale] = React.useState('M');
  
 

  const calculateMaxSliderValue = () => {
    const mapping = alignment === 'annual' ? annualMapping : qtrMapping;
    return Math.min(mapping.length, 20);
  };
  
  const maxSliderValue = calculateMaxSliderValue();
  const initialSliderValue = Math.min(isMobile ? 2 : isTablet ? Math.min(5, maxSliderValue) : Math.min(8, maxSliderValue), maxSliderValue);
  const [sliderValue, setValue] = useState(initialSliderValue);
  
  


  useEffect(() => {
    const maxSliderValue = calculateMaxSliderValue();
    const initialSliderValue = isMobile ? 2 
                          : isTablet ? Math.min(5, maxSliderValue) 
                          : Math.min(8, maxSliderValue);
    setValue(initialSliderValue);
  }, [annualData]);


  
  useEffect(()=>{
    const slicedMapping = alignment === 'annual' 
    ? annualMapping.slice(-sliderValue) 
    : alignment === 'quarterly' 
    ? qtrMapping.slice(-sliderValue) 
    : [];

  const qtrGraphSlicedMapping = qtrMapping.slice(-4 * sliderValue);
  const filteredAnnualData = annualData.filter(item => metrics.includes(item.field));
  const filteredQtrData = qtrData.filter(item => metrics.includes(item.field));

  setMappingArray(slicedMapping);
  setFilteredData(alignment === 'annual' ? filteredAnnualData : alignment === 'quarterly' ? filteredQtrData : []);

  const graphFilteredData = alignmentGraph === 'annual' ? annualData : alignmentGraph === 'quarterly' ? qtrData : [];
  const graphMappingArray = alignment === 'annual' 
    ? (alignmentGraph === 'annual' ? slicedMapping : qtrGraphSlicedMapping) 
    : alignment === 'quarterly' 
    ? qtrMapping.slice(-sliderValue) 
    : [];
  const convertToNivoFormat = (field) => {
    const filtered = graphFilteredData.find(item => item.field === field);
  if (!filtered) return [];

  // Filter the data based on the periods in annualSlicedMapping and format it for Nivo
  
  const formattedData = {
    id: field,
    data: Object.keys(filtered)
      .filter(key => key !== 'field' && graphMappingArray.some(mapping => mapping.period === key))
      .map(key => ({
        x: key,
        y: filtered[key]
      }))
  };

  return [formattedData];
  };
  setNivoData(convertToNivoFormat(selectedTrendedMetric));
},[annualData,sliderValue,alignment,alignmentGraph,selectedTrendedMetric])

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);

  };
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = () => {
    if (sliderValue < 2 || isNaN(sliderValue)) {
      setValue(2);
    } else if (sliderValue > maxSliderValue) {
      setValue(maxSliderValue);
    }
  };
  useEffect(() => {
    const maxSliderValue = calculateMaxSliderValue();
    const initialSliderValue = isMobile ? 2 
                          : isTablet ? Math.min(5, maxSliderValue) 
                          : Math.min(8, maxSliderValue);
    setValue(initialSliderValue);
  }, [annualData]);
  
  const valueFormatter = (params) => {
    const metricName = params.data.field; // The metric name
    const value = params.value;
    if (value === null || value === undefined) {
      return '-';  // Display 'N/A' for missing values
    }
    // Define your conditional formatting rules
    if (metricName ==="Revenue"||metricName ==="ARR"||metricName ==="Cloud ARR"||metricName ==="Non-Cloud ARR" ||metricName ==="Cloud Revenue" ||metricName ==="Non-Cloud Revenue" ||metricName ==="Calculated Billings" ||metricName ==="Current Bookings"||metricName ==="Bookings" ||metricName ==="Current RPO"  ||metricName ==="RPO") {
      // Format as billions ($B)
      if (alignmentScale === "M"){
        return `$${(value / 1e6).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`
      }else{
        return `$${(value / 1e9).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}B`
      }

    } else if (metricName ==="Rule of 40" || metricName ==="Cloud Mix"|| metricName ==="Net Retention Rate") {
      // Format as a percentage (%)
      if (value >1){
      
        return `${(value*100).toFixed(0)}%`;
      } else{
      return `${(value*100).toFixed(1)}%`;
    }
    }
    else if (metricName ==="Customers" || metricName ==="Customers >$100k"|| metricName ==="Customers >$1M") {
      // Format as a percentage (%)
      return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
  

    // Default formatting (if no specific rule applies)
    return value.toFixed(1);
  };
  const columnDefs = useMemo(() => {
    const columns = [
      { headerName: alignment === 'annual' ? 'Fiscal Year' : 'Fiscal Quarter',
        headerComponentParams: { template: `<div class="ag-header-cell-label"> <span class="ag-header-cell-text"> <strong>${alignment === 'annual' ? 'Fiscal Year' : 'Fiscal Quarter'}</strong><br/> <span style="font-size: 0.8em;">Period Ended</span> </span> </div>`, },
        headerClass: "left-align-header",  
        cellStyle: { paddingRight: '0px' }, 
        
        pinned:'left',width:190, field: "field", sortable: false, filter: false,rowDrag:true }
    ];

    // Dynamically add fiscal year columns
    mappingArray.forEach(mapping => {
      columns.push({
        headerName: mapping.period, // Use "period" as the column header
        headerComponentParams: { template: `<div class="ag-header-cell-label"> <span class="ag-header-cell-text"> <strong>${mapping.period}</strong><br/> <span style="font-size: 0.8em;">${mapping.endDate}</span> </span> </div>`, },
        headerClass: "right-align-header",  
        field: mapping.period,          // Field matches the fiscal year (e.g., FY21, FY22)
        sortable: false,
        filter: false,
        type: "numericColumn",
        width:100,
        valueFormatter
      });
    });
    return columns;
  }, [mappingArray,alignmentScale]);
  useEffect(() => {
    // Calculate and set tableWidth whenever columnDefs changes
    const totalWidth = columnDefs.reduce((sum, colDef) => sum + colDef.width+1, 0) + 12;
    setTableWidth(totalWidth);
  }, [columnDefs]); // Add columnDefs as a dependency
  
  const [tableWidth, setTableWidth] = useState([columnDefs.reduce((sum, colDef) => sum + colDef.width+1, 0) + 12]);



  return(
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <Box
      sx={{
        height:'auto',
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
        <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
          <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <Box sx={{ display: 'flex', width:'1020px',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              maxWidth: '1500px',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile?'100%':'65px',
                width: isMobile?'100%':'65px',
                fontSize: '12px',
                textAlign: isMobile?'left':'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Dashboard Filters
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <ButtonTimeFrame alignment={alignment} handleChange={handleChange}/>
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <ButtonFormatValues alignment={alignmentScale} handleChange={handleChangeScale}/>
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <FilterSlider period={alignment === "annual" ? "Years" : "Quarters"} sliderValue={sliderValue} maxSliderValue={maxSliderValue} handleSliderChange={handleSliderChange} handleInputChange={handleInputChange} handleBlur={handleBlur}/>
              </Box>
              <Box
              sx={{
                maxWidth: isMobile?'100%':'65px',
                width: isMobile?'100%':'65px',
                fontSize: '12px',
                textAlign: isMobile?'left':'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Chart Filters
            </Box>
            <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <ButtonTimeFrame alignment={alignmentGraph} handleChange={handleChangeGraph}/>
              </Box>
              <Box sx={{marginTop:'4px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <Box sx={{ height:'30px', width: '150px' }}>
            <FormControl sx={{ height:'30px',paddingLeft: '0%', width: '170px' }}>
              <Select
                value={selectedTrendedMetric}
                onChange={(event) => setSelectedTrendedMetric(event.target.value)}
                renderValue={(value) => (
                  <Typography
                    variant="body2"
                    sx={{
                      
                      fontSize: '12px',
                      display: 'flex',
                      alignContent: 'center',
                      paddingTop: '3px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {value}
                  </Typography>
                )}
                displayEmpty
                size="small"
                sx={{height: '30px',backgroundColor:theme.tableHeader.highlight}}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                
                {filteredData.map((item, index) => (
                <MenuItem sx={{ fontSize:'13px'}} key={index} value={item.field}>
                  {item.field}
                </MenuItem>
              ))}
              </Select>
            </FormControl>

          </Box>
          </Box>
          
            </Box>
          </Box>
        </Box>


        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
    </Box>
    </Collapse>
    </Box>
    
    {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress /> {/* This is the MUI spinner */}
      </Box>):
      (        
  <Box sx={{flexGrow: 1, width:'100%'}}>
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'45%',maxHeight:'45%',width:'100%',maxWidth:'100%'}}>

 
      {!isPhablet&&<Box  sx={{ marginLeft:'1%',height: '100%',width: '230px', marginBottom: '0px'}}>
        <TableMetrics keyStats={keyStats} />
      </Box>}  
      <Box  sx={{ height: '100%',maxWidth: isPhablet?'100%':'calc(100% - 230px)', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
      {nivoData && nivoData.length > 0 &&sliderValue >0 ?<GraphTrended nivoData={nivoData} selectedTrendedMetric={selectedTrendedMetric}/>:"Loading"}
      </Box>

        </Box>
        
  <Box elevation={3} sx={{ height: '50%', maxHeight: '50%', marginTop: '0px', display: 'flex', flexDirection: 'column' }}>
  <Box sx={{ paddingBottom:'.5%',display: 'flex', justifyContent: 'center', width: '100%',marginBottom:'8px', }}>
    <Divider sx={{   width: tableWidth -100,maxWidth:'80%' }} />
  </Box>
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
    {/* Center the table container */}
    <Box sx={{ maxWidth: isTablet ?'100%':'80%', height: '100%' }}>
      <TableTrended
        ticker = {ticker}
        alignmentScale={alignmentScale}
        columnDefs={columnDefs}
        filteredData={filteredData}
        tableWidth={tableWidth}
        setSelectedTrendedMetric={setSelectedTrendedMetric}
      />
    </Box>
  </Box>
</Box>

 
    </Box>)}

    </Box>

      )}
      export default SecurityAnalysisKeyMetrics;