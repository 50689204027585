import React from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {Paper, Box, Typography } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import { AgGridReact } from "ag-grid-react";


const SecurityAnalysisKeyMetrics = ({keyStats}) => {  

  const theme = useTheme();


  const columnDefsKeyStats = [
    {
      sortable: false, filter: false,
      headerName: "Metrics",
      field: "field",
      width:120,
      minWidth:120,
        
      resizable: false, // Disable resizing for this column
      suppressMovable: true, // Disable moving for this column
      cellRenderer: (params) => (
        <>
          {<Typography sx={{ fontSize:'13px',margin: 0, padding: 0 }}>{params.value}</Typography>}
          {<Typography sx={{ fontSize:'9px',color:theme.palette.text.secondary,display:'block',margin: 0, padding: 0 }}>
            {params.data.subField}
          </Typography>}

        </>
      ),
      flex: 1,
    },
    {
      sortable: false, filter: false,
      headerName: "",
      width:100,
      minWidth:100,
      field: "value",
      cellStyle: { textAlign: 'right' },
    suppressMovable: true, // Disable moving for this column
    resizable: false, // Disable resizing for this column
    },
  ];
  // Filter the data based on the periods in annualSlicedMapping and format it for Nivo
  


  
  // Define the default column properties
  const defaultColDef = {
    resizable: false,

  };
  
  return(
    <>    
    <Box sx={{ 
      paddingBottom: '6px', 
      color: theme.palette.primary.main, 
      fontSize: '24px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '0.5%',
      }}
      style={{ fontWeight: '300' }} // Inline style for testing
      >Valuation Metrics</Box>
      <Paper elevation={1}
                className={theme.palette.mode === 'dark' ? "ag-theme-material-dark" : "ag-theme-material"}
                
                style={{
                  width: "100%",
                  "--ag-odd-row-background-color": theme.tableHeader.highlight,
                  "--ag-row-hover-color": theme.tableHeader.highlightDark,
                  "--ag-material-primary-color": theme.palette.primary.main,
                  "--ag-material-accent-color": theme.palette.primary.main,
                  "--ag-header-cell-color": theme.palette.text.secondary, // For text color
                  "--ag-header-cell":theme.tableHeader.highlight,
                  "--ag-header-height": "50px", // Optional: Adjusts the header height
                }}
                sx={{
                  width: "100%",
                  "& .ag-header-cell": {
                    backgroundColor: theme.background.backgroundColor,
                    color: theme.palette.text.secondary,
                  },
              

                }}

              >
        <AgGridReact
          rowData={keyStats}
          columnDefs={columnDefsKeyStats}
          rowHeight={40}
          headerHeight={40}
          
          domLayout={"autoHeight"}
          getRowStyle={(params) => ({
            backgroundColor: params.node.rowIndex % 2 === 0 
              ? theme.tableHeader.highlight 
              : theme.tableHeader.highlight , // Alternate row color
            color: theme.palette.text.secondary,
            transition: "background-color 0.3s ease",
          })}
          onRowMouseEnter={(params) => {
            const rowNode = params.node;
            rowNode.setRowStyle({
              backgroundColor: theme.tableHeader.highlightDark,
            });
          }}
          onRowMouseLeave={(params) => {
            const rowNode = params.node;
            rowNode.setRowStyle({
              backgroundColor: params.node.rowIndex % 2 === 0 
                ? theme.tableHeader.highlight 
                : theme.tableHeader.highlight ,
            });
          }}
        />
    </Paper>
    </>


      )}
      export default SecurityAnalysisKeyMetrics;