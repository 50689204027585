import React, { useState, useEffect } from 'react';
import '../index.css';
import { useLocation } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Collapse,useMediaQuery,Divider,IconButton,Box } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import { format, parseISO } from 'date-fns';
import TableCapTable from '../components/TableCapTable'
import { ResponsiveLine } from "@nivo/line";


const MyResponsiveLine = ({ data, theme }) => {
  
  
  const isDarkMode = theme.palette.mode === 'dark';

  const themeGraph = {
    textColor: isDarkMode ? 'white' : 'black',
    grid: {
      line: {
        stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      },
    },
    axis: {
      legend: {
          text: {
              fill: isDarkMode ? 'white' : 'black',
              fontWeight: 'bold',
          },
      },
      ticks: {
          text: {
              fill: isDarkMode ? 'white' : 'black',
          },
      },
  },
    markers: {
      lineColor: isDarkMode ? 'white' : 'black',
      textColor: isDarkMode ? 'white' : 'black',
    },
    tooltip: {
      container: {
        background: isDarkMode ? '#323232' : '#FFFFFF',
        color: isDarkMode ? 'white' : 'black',
      },
    },
    legends: {
      text: {
        fill: isDarkMode ? 'white' : 'black', // Set legend label color to white in dark mode
      },
    },
    
  };
  const formatValue = (value) => {
    if (value ===""){
      return value
    }
    const absValue = Math.abs(value);
    
    if (absValue >= 1e11) {
      // Format as billions with three significant digits
      return `${(value / 1e9).toFixed(0)}B`;
    }else if (absValue >= 1e9) {
      // Format as billions with three significant digits
      return `${(value / 1e9).toFixed(1)}B`;
    } else if (absValue >= 1e7) {
        // Format as billions with three significant digits
        return `${(value / 1e6).toFixed(0)}M`;
    } else if (absValue >= 1e6) {
      // Format as millions with three significant digits
      return `${(value / 1e6).toFixed(1)}M`;
    } else if (absValue >= 1e3) {
      // Format as thousands with three significant digits
      return `${(value / 1e3).toFixed(1)}K`;
    } else if (absValue >= 3) {
      // Format as is with three significant digits
      return value.toFixed(2);
    }
    else if (absValue === 0) {
      return value.toFixed(0);
    }
    else if (absValue >= 1){
      
      return `${(value * 100).toFixed(0)}%`;
    }
    else {
      return `${(value * 100).toFixed(1)}%`;
    }
  };
  

return (
  <ResponsiveLine
  data={data}
  theme={themeGraph}
  margin={{ top: 38, right: 50, bottom: 50, left: 75 }}
  colors={theme.palette.mode === 'dark' ? { scheme: 'dark2' } : { scheme: 'category10' }}
  xScale={{
    type: 'time',
    format: '%Y-%m-%d',
    useUTC: false,
    precision: 'day',
}}
  
  yScale={{
      type: 'linear',
      min: 0,
      max: 'auto',
      stacked: false,
      reverse: false
  }}
  yFormat={(value) => formatValue(value)}
  axisTop={null}
  axisRight={null}
  axisBottom={{
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -45,  
    format: '%b-%y',
    tickValues: 'every 2 month',
    legendOffset: -12,
}}
  axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'ARR',
      legendOffset: -60,
      legendPosition: 'middle',
      format: (value) => formatValue(value),
      
  }}
  pointSize={10}
  pointColor={{ theme: 'background' }}
  curve="cardinal"
  pointBorderWidth={2}
  pointBorderColor={{ from: 'serieColor' }}
  pointLabelYOffset={-12}
  useMesh={true}
  enableGridX={false} // Hide horizontal gridlines
  enableGridY={true} // Hide vertical gridlines
  
  enablePoints={true} 
  enableArea={true}
  enableSlices="x"
  areaOpacity={0.05} // Set the opacity of the area
  
  legends={[
    {
        anchor: 'top-right',
        direction: 'column',
        justify: false,
        translateX: -80,
        translateY: -40,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 95,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
            {
                on: 'hover',
                style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                }
            }
        ]
    }
]}
sliceTooltip={({ slice }) => {
  return (
    <Box
      sx={{
        background: isDarkMode ? '#323232' : '#FFFFFF',
        color: isDarkMode ? 'white' : 'black',
        padding: '0px',
        borderRadius: '3px',
        boxShadow: isDarkMode
          ? '0 1px 2px rgba(255, 255, 255, 0.15)'
          : '0 1px 2px rgba(0, 0, 0, 0.15)',
      }}
    >
      <div style={{display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:theme.tableHeader.color,padding:'4px',fontSize: '12px', marginBottom: '5px', fontWeight: 'bold' }}>
        {format(new Date(slice.points[0].data.x), 'MM/dd/yy')}
      </div>
      {slice.points.map(point => (
        <Box key={point.id} sx={{ padding:'8px',display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
          <Box
            sx={{
              width: '12px',
              height: '12px',
              backgroundColor: point.serieColor,
              borderRadius: '50%',
              marginRight: '8px',
            }}
          />
          <div style={{ fontSize: '12px' }}>
            <strong>{point.serieId}</strong>: {formatValue(point.data.y)}
          </div>
        </Box>
      ))}
    </Box>
  );
}}
  
/>
)
}



const NewStockPage = ({companyName,ticker,open,handleToggleCollapse}) => {
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
  const shouldWrap2 = useMediaQuery('(max-width: 600px)');
  const formatPercentage = (num) =>{
    if (num < 1){
      return `${(num* 100).toFixed(1)}%`;
    }
    else{
      return `${(num* 100).toFixed(0)}%`;
    }
    
  }
  function formatNumber(num) {
    if (num === null || num === undefined) return 'N/A';
    if (num >= 1e9) {
      return `$${(num / 1e9).toFixed(1)}B`;
    } else if (num >= 1e6) {
      return `$${(num / 1e6).toFixed(0)}M`;
    } else {
      return num.toLocaleString();
    }
  }
  const theme = useTheme();
  const location = useLocation(); 
  const [capTable, setCapTable] = useState([]);
  const [financials, setFinancials] = useState([]);
  const tableContainerWidth = 1200

  useEffect(() => {
    setFinancials([])
    const fetchData = async () => {
      try {
        // Ensure ticker is a string and log it for debugging
        const dynamicCapTable = await import(`../data/stockpage/private/captable/${ticker}.json`);
        

        // Set the data in state, assuming dynamicData.default is an array of arrays as per provided data format
        if (Array.isArray(dynamicCapTable.default)) {
          setCapTable(dynamicCapTable.default);
        }

        
        const dynamicFinancials = await import(`../data/stockpage/private/financials/${ticker}.json`);
        if (Array.isArray(dynamicFinancials.default)) {

            setFinancials(dynamicFinancials.default);
  
          // Check the last revenue value and set the scale to 'B' if it's greater than $10B
        }
        else {
          console.error('Unexpected data format:', dynamicCapTable);
        } 

      } catch (error) {
        console.error(`Error fetching data for ${ticker}:, error`);
      }
    }

    fetchData();
  }, [ticker]);

  

  
  // Function to handle button clicks and update the selected button state
  
  
  const hasFinancials = financials.length > 0;


  const graphWidth = 1200
  return(
    <Box sx={{ height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
    <Box
      sx={{
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
                <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', width:'100%',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >

        </Box>


         </Box>



          <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '10%',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        </Box>
        </Collapse>
        

    </Box>


    
    <Box elevation={3} sx={{ height: '50%', maxHeight: '50%', marginTop: '0px', display: 'flex', flexDirection: 'column' }}>
        {hasFinancials && 
        <Box sx={{ maxWidth: "98%",height:'100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ maxWidth: "98%",height:'100%', flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Box sx={{ 
            display: 'flex', 
            justifyContent:'space-between',
            paddingBottom: '6px', 

            maxWidth: "98%", 
            color: theme.palette.primary.main, 
            fontSize: '24px', 
            fontWeight: '300 ' , // Use !important to enforce the style
            textAlign: 'left', // Ensure text is left-aligned
            paddingLeft: '.5%',
        }}
        style={{ fontWeight: '300' }} // Inline style for testing
        >
    
          Reported ARR
      
        </Box>
        <Box sx={{ display: 'flex', height:'38vh', maxWidth:'100%',width: graphWidth}}>
        <div
          style={{
            position: 'relative',
            maxWidth:'100%',
            flex: 1, // This allows it to grow and fill the container
          }}
        >
          <div style={{
            position: 'absolute', width: '100%', height: '98%',
          }}>
          <MyResponsiveLine data = {financials} theme={theme}/>
              <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 50, // Adjust the distance from the bottom
                right: 62, // Adjust the distance from the right
                width: '130px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.4, // Set the desired opacity for the watermark
              }}
            />
            </div>
            </div>
        </Box>
        </Box>
        </Box>}
        <Box sx={{ paddingBottom:'.5%',display: 'flex', justifyContent: 'center', width: '100%',marginBottom:'8px', }}>
          <Divider sx={{   width: '70%',maxWidth:'80%' }} />
        </Box>
    <Box elevation={3} sx={{ paddingBottom:'0px',paddingTop: '6px', marginTop: '6px', flex: 1, paddingLeft: '2%',marginLeft:'1%',paddingRight:'1%',marginRight:'0%' }}>
<Box sx={{height:'38vh'}}>
          <TableCapTable companyName={companyName} filteredData={capTable}/>
          </Box>
    </Box>
    </Box>

  </Box>
      )}
      export default NewStockPage;