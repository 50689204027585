import React from "react";
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import {  useEffect } from 'react';
import { alpha,Container,Typography,Stack,Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';



function Home(){
  const theme = useTheme();
  const location = useLocation(); // If using React Router

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);

    return(
      <>
       
      <Container
        sx={{
          position: 'relative', // Add relative positioning
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>

        <Typography
          component="h1"
          variant="h1"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignSelf: 'center',
            textAlign: 'center',
            marginTop: '-110px',
            color: theme.palette.mode === 'dark' ? '#FFF' : 'inherit',
            fontSize: {
              xs: theme.typography.h2.fontSize, // smaller font size for xs screens
              md: theme.typography.h1.fontSize, // default font size for md screens
            },
          }}
        >
          Software
          <Typography
            component="span"
            variant="h1"
            sx={{
              color: theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              fontSize: {
                xs: theme.typography.h2.fontSize, // smaller font size for xs screens
                md: theme.typography.h1.fontSize, // default font size for md screens
              },
            }}
          >
            Research Platform
          </Typography>
        </Typography>
        </Stack>
        <Box
          sx={{
            textAlign: 'center',
            py: 4,  // Padding for top and bottom
            maxWidth: { xs: '100%', md: '90%', lg: '80%' },  // Responsive maxWidth
            mx: 'auto',
          }}
>
  <Typography
    variant="body1"
    color="text.secondary"
    sx={{
      fontSize: { xs: '1rem', md: '1.3rem' },  // Responsive font size
      lineHeight: 1.75,                         // Line height for readability
      fontWeight: 500,                          // Slightly bolder text
      letterSpacing: '0.5px',                   // Letter spacing for clarity
      mx: 'auto',                               // Center the text horizontally
    }}
  >
    {/* First Paragraph */}
    <Box component="span" sx={{ display: 'block', mb: 2 }}>  
      Explore an <Box component="span" sx={{ color: 'primary.main', fontWeight: 600 }}>investor-grade research platform</Box> designed for the software industry.
    </Box>

    {/* Second Paragraph */}
    <Box component="span" sx={{ display: 'block', mb: 2 }}>
      Access <Box component="span" sx={{ color: 'primary.dark', fontWeight: 600 }}>industry specific data sets</Box> and visualizations, covering both private and public companies, tailored to capital markets and industry participants.
    </Box>

    {/* Third Paragraph */}
    <Box component="span" sx={{ display: 'block', mb: 2 }}>
      <Box component="span" sx={{ color: 'secondary.main', fontWeight: 600 }}>Supercharge</Box> your analysis with a platform built for precision and impact.
    </Box>
  </Typography>

  {/* Decorative Element */}
  <Box
    sx={{
      mt: 2,
      width: '50px',
      height: '4px',
      backgroundColor: 'primary.main',
      mx: 'auto',  // Center it horizontally
      borderRadius: 2,
    }}
  />
</Box>

        
        <Box
          id="image"
          style={{
            marginTop: "20px",
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
            alignSelf: 'center',
            height: 'auto', // Adjust height dynamically based on image height
            width: 'auto', // Constrain the width to the width of the image
            maxWidth: '100%', // Ensure the container doesn't exceed the viewport width
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor: theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
            boxShadow: theme.palette.mode === 'light' ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}` : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          }}
        >
          <Box sx={{display:'flex',justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography component="h2" variant="h4" sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}>
              Security Analysis
            </Typography>
            
            </Box>
            <Typography
              variant="body1"
              color="text.secondary"
            >
              Explore long-term trends in gaap and non-gaap metrics for software securities.
            </Typography>
          <img
            src={
              theme.palette.mode === 'light'
                  ? require('../data/images/mainpage.png')
                  : require('../data/images/mainpagedark.png')
            }
            alt="Maing Page"
            style={{
              display: 'block', // Ensure the image takes up the full width of its container
              width: '100%', // Ensure the image takes up the full width of its container
              height: '100%', // Ensure the image takes up the full height of its container
              objectFit: 'contain', // Constrain the image to fit within its container while preserving its aspect ratio
              borderRadius: '10px', // Apply border radius to match the container's border radius
            }}
          />
        </Box>
      </Container>

      </>
    )
}
export default Home